<template>
  <div class="justify-content-md-start">
    <div v-if="isLoading" class="mb-5">
      <b-spinner
        type="grow"
        small
        variant="primary"
        class="mx-auto mt-3 d-block"
      />
    </div>
    <div v-else>
      <span class="d-flex justify-content-end flex-wrap mb-2">
        <feather-icon
          v-if="canDelete"
          icon="Trash2Icon"
          size="18"
          class="text-primary delete-icon"
          role="button"
          @click="deleteItem()"
        />
        <b-dropdown
          v-if="canEdit"
          id="dropdown-dropleft"
          variant="link"
          right
          class="text-primary edit-icon"
          no-caret
        >
          <template #button-content>
            <feather-icon icon="Edit2Icon" size="18" />
          </template>
          <b-dropdown-text class="bg-light">
            <p class="user-name font-weight-bolder mb-0">
              {{ $t("edit-dropdown.title") }}
            </p>
          </b-dropdown-text>
          <b-dropdown-item v-b-modal.modal-edit-tasks>
            {{ $t("edit-dropdown.details") }}
          </b-dropdown-item>
          <b-dropdown-item @click="isClassifiersModalVisible = true">
            {{ $t("members.edit.classifiers.name") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canEdit"
            @click="isCustomFieldsModalVisible = true"
          >
            {{ $t("members.edit.custom-fields.label") }}
          </b-dropdown-item>
        </b-dropdown>
      </span>
      <b-card class="mx-auto w-100 mt-n5">
        <div class="d-flex flex-wrap justify-content-between align-items-center">
          <div>
            <!-- Status -->
            <b-button 
              :disabled="!canEdit" 
              v-b-modal="canEdit ? 'editStatus' : ''" 
              :active="true" 
              :variant="resolveStatusVariant(task.status)" 
              class="align-self-start" 
              :style="!canEdit ? 'opacity: 1' : ''"
            >
              <span class="font-weight-bold text-capitalize">
                {{ getStatus(task.status) }}
              </span>
            </b-button>
            <!-- Assignee -->
            <b-avatar
              v-if="item.assignee"
              v-b-modal="canEdit ? 'editAssignee' : ''"
              v-b-tooltip.hover
              :title="`${item.assignee.name} ${item.assignee.surname} `"
              size="40"
              :src="getImageSrc(item.assignee.avatarURL)"
              class="ml-2"
              :style="!canEdit ? 'cursor: initial' : ''"
              style="border-style: groove; border-color: grey; border-width: 2px;"
            />
            <b-avatar
              v-else
              v-b-modal="canEdit ? 'editAssignee' : ''"
              v-b-tooltip.hover
              size="40"
              variant="white"
              class="ml-2"
              style="border-style: dotted; border-color: darkgrey; border-width: 2px "
              :style="!canEdit ? 'cursor: initial' : ''"
            />
            <!-- Priority -->
            <b-icon
              v-b-modal="canEdit ? 'modal-edit-priority' : ''"
              icon="flag-fill"
              scale="1.3"
              :variant="resolveTagVariant(task.priority)"
              class="mt-1 cursor-pointer ml-2"
              style="outline-style: none; color: lightgrey;"
              :style="!canEdit ? 'cursor: initial' : ''"
            />
            <span class="mt-50 ml-50">
              <small>{{ getTranslatedPriority(item.priority) }}</small>
            </span>
          </div>
          <!-- Due date -->
          <div v-if="task.dueDate != 0">
            <p class="mb-0 uppercase text-muted">
              <small>{{ $t("tasks.status.due-date") }}</small>
            </p>
            {{ new Date(task.dueDate).toLocaleDateString() }}
          </div>
        </div>
        <hr></hr>
        <!-- Attachments -->
        <b-row>
          <b-col cols="12" :lg="task.attachments && task.attachments.length > 0 ? 6 : 12" class="pt-2">
            <div v-if="!task.attachments || task.attachments.length  == 0" class="">
              <b-badge
                variant="primary"
                class="ml-25 font-weight-normal pointer float-right"
                @click="attachImages()"
              >
                <feather-icon icon="PaperclipIcon" class="mr-25" />
                {{ $t('tasks.attach') }}
              </b-badge>
            </div>
            <h4>
              {{ task.title }}
            </h4>
            <p v-if="task.description" class="mt-1">
              <span v-html="task.description" />
            </p>
            <p v-else class="text-muted mt-1">
              ---
            </p>
            <div v-if="(task.classifiers && task.classifiers.length > 0) || task.custom && task.custom.length > 0" class="border-top w-25 my-1"/>
            <!-- Classifiers -->
            <template v-if="task.classifiers && task.classifiers.length > 0">
              <div v-for="(value, name) in sortedClassifiers" :key="value.key">
                <div class="w-100">
                  <span class="font-weight-bold">{{ name }}:</span>
                </div>
                <span v-for="v in value" :key="v.key">
                  <b-badge class="mb-50 mr-50" :style="classifierStyle(v)">
                    {{
                      translateTranslationTable(
                        $store.state.locale.currentLocale,
                        v.name
                      )
                    }}
                  </b-badge>
                </span>
              </div>
            </template>
             <!-- Custom -->
             <template v-if="task.custom && task.custom.length > 0">
              <div
                v-for="(field, index) in task.custom"
                :key="index"
                class="mt-1"
              >
                <div
                  v-if="
                    field.value ||
                    (typeof field.value !== 'string' && !field.value)
                  "
                  class="mb-1 d-flex flex-wrap"
                >
                  <div class="font-weight-bold mr-2">
                    {{ getName(field) }}:
                  </div>
                  <div>
                    {{ getValue(field.value) }}
                  </div>
                </div>
              </div>
            </template>
          </b-col>
          
          <b-col
            v-if="task.attachments && task.attachments.length > 0"
            cols="12"
            lg="6"
            :class="isSmall ? 'border-top' : 'border-left' "
            class="pl-2 pt-2"
          >
            <div class="d-flex flex-wrap align-items-start justify-content-between">
              <h5 class="mb-2">
                {{ $t('tasks.attachments') }}
              </h5>
              <b-badge
                variant="primary"
                class="ml-25 font-weight-normal pointer"
                @click="attachImages()"
              >
                <feather-icon icon="PaperclipIcon" class="mr-25" />{{$t('tasks.attach')}}
              </b-badge>
            </div>
            <div class="d-flex flex-wrap">
              <!--  -->
              <b-card
                v-for="(attach, index) in projectInput.attachments"
                class="mr-1"
                style="height: fit-content; width: fit-content"
                @mouseleave="action = null"
                @mouseover="action = index"
              >
                <safe-img
                  v-if="attach.file.includes('image')"
                  :src="attach.file"
                  style="height: 150px; max-width: auto"
                  class="d-block mx-auto mb-1"
                  :retry="5000"
                />
                <feather-icon v-else-if="attach.file.includes('audio')" icon="MusicIcon" size="128" />
                <feather-icon v-else icon="FileTextIcon" size="128" />
                <div v-if="action === index">
                  <!-- <p class="text-center truncated-text mb-25"><small>{{attach.title}}</small></p> -->
                  <div class="text-center">
                    <b-avatar
                      v-if="attach.createdBy.avatar_url"
                      :title="`${attach.createdBy.name} ${attach.createdBy.surname} `"
                      size="20"
                      :src="getImageSrc(attach.createdBy.avatarURL)"
                    />
                  </div>
                  <p class="text-center mb-0">
                    <small>{{ attach.createdBy.name }} {{ attach.createdBy.surname }}</small>
                  </p>
                  <div>
                    <div class="text-center">
                      <feather-icon
                        icon="DownloadIcon"
                        size="16"
                        class="text-primary text-center"
                        role="button"
                        @click="download(attach.file)"
                      />
                    </div>
                  </div>
                </div>
                <div v-else class="text-center ">
                  <small class="text-muted mb-0">
                    {{ date(attach.createdAt) }}
                  </small>
                </div>
              </b-card>
            </div>
          </b-col>
        </b-row>
        <hr></hr>
        <div>
          <div class="d-flex align-items-center">
            <!-- Author and date -->
            <div>
              <small>
                {{ $t("tasks.status.created-by") }}
                <span class="font-weight-extrabold mb-0">
                  {{ task.ownedByMember.name }}
                  {{ task.ownedByMember.surname }}
                </span>
              </small>
              <small class="text-muted mb-0">
                {{ date(task.createdAt) }}
              </small>
            </div>
          </div>
        </div>
      </b-card>
      <!-- Edit Assignee -->
      <b-modal
        id="editAssignee"
        :title="$t('tasks.status.assignee-modal')"
        :ok-title="$t('form.actions.save')"
        no-close-on-backdrop
        ok-only
        @ok="handleEdit"
      >
        <b-form-group class="flex-grow-1">
          <label for="suggestionType" class="font-weight-bold">
            {{ $t('tasks.status.assignee-label') }}:</label>
          <members-select
            v-model="assignee"
            class="style-chooser"
            dir="ltr"
            :multiple="false"
            :clearable="false"
            :placeholder="$t('members.search-placeholder')"
            searchable
            :fetch-data="fetchMembers"
            label="name"
          >
            <template #option="option">
              <b-avatar size="sm" :src="option.avatarURL" />
              <span class="ml-50 align-middle">{{ option.name }} {{ option.surname }}</span>
            </template>
            <template #selected-option="option">
              <b-avatar
                size="sm"
                class="border border-white"
                :src="option.avatarURL"
              />
              <span class="ml-50 align-middle">{{ option.name }} {{ option.surname }}</span>
            </template>
          </members-select>
        </b-form-group>
      </b-modal>
      <!-- Edit status -->
      <b-modal
        id="editStatus"
        :title="$t('tasks.status.title')"
        :ok-title="$t('form.actions.save')"
        no-close-on-backdrop
        ok-only
        @ok="handleEdit"
      >
        <b-form class="form-challenge-idea " @submit.prevent="handleCreateAnswer">
          <b-form-group class="flex-grow-1">
            <label for="suggestionType" class="font-weight-bold">
              {{ $t('tasks.status.label') }}:</label>
            <v-select
              v-model="status"
              name="suggestionType"
              :searchable="false"
              label="name"
              :clearable="false"
              :options="typeOptions"
            />
          </b-form-group>
        </b-form>
      </b-modal>
      <!-- Edit Task Modal -->
      <b-modal
        id="modal-edit-tasks"
        :title="isAttaching ? $t('tasks.attachments') : $t('tasks.edit.title')"
        :ok-title="$t('form.actions.save')"
        :cancel-title="$t('form-create-item.cancel')"
        cancel-variant="outline-secondary"
        centered
        size="lg"
        ok-only
        :ok-disabled="!projectInput.title"
        @show="reset"
        @hide="isAttaching = false"
        @ok="handleEdit"
      >
        <b-form>
          <div v-if="!isAttaching">
            <b-form-group>
              <label for="name"> {{ $t("form-create-item.title") }}*:</label>
              <b-form-input
                id="name"
                v-model="projectInput.title"
                type="text"
              />
            </b-form-group>
            <b-row>
              <b-col>
                <b-form-group class="flex-grow-1">
                  <label for="suggestionType" class="font-weight-bold">
                    {{ $t("tasks.status.assignee") }}:</label>
                  <members-select
                    v-model="assignee"
                    class="style-chooser"
                    dir="ltr"
                    :multiple="false"
                    :clearable="false"
                    :placeholder="$t('members.search-placeholder')"
                    searchable
                    :fetch-data="fetchMembers"
                    label="name"
                  >
                    <template #option="option">
                      <b-avatar size="sm" :src="option.avatarURL" />
                      <span
                        class="ml-50 align-middle"
                      >{{ option.name }} {{ option.surname }}</span>
                    </template>
                    <template #selected-option="option">
                      <b-avatar
                        size="sm"
                        class="border border-white"
                        :src="option.avatarURL"
                      />
                      <span class="ml-50 align-middle">{{ option.name }} {{ option.surname }}</span>
                    </template>
                  </members-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="flex-grow-1">
                  <label for="suggestionType" class="font-weight-bold">
                    {{ $t("tasks.status.status") }}:</label>
                  <v-select
                    v-model="status"
                    name="suggestionType"
                    :searchable="false"
                    label="name"
                    :clearable="false"
                    :options="typeOptions"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label for="dueDate" class="font-weight-bold">
                  {{ $t("tasks.status.due-date") }}:</label>
                <VueCtkDateTimePicker
                  id="dueDate"
                  v-model="dueDate"
                  :locale="locale"
                  format="YYYY-MM-DD"
                  formatted="ll"
                  :label="$t('backoffice.availability.date-no-slots')"
                  color="var(--primary)"
                  only-date
                  no-button-now
                  button-color="var(--primary)"
                />
              </b-col>
              <b-col>
                <b-form-group class="flex-grow-1">
                  <label for="priority" class="font-weight-bold">
                    {{ $t("tasks.status.priority") }}:</label>
                  <v-select
                    v-model="priority"
                    name="priority"
                    :placeholder="$t('form.select-placeholder')"
                    :searchable="false"
                    :clearable="false"
                    :options="priorityOptions"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group>
              <label class="mt-2" for="description">{{ $t("form-create-item.description") }}:</label>
              <quill-editor v-model="projectInput.description" />
            </b-form-group>
          </div>
          <!-- Attachment -->
          <div v-if="isAttaching">
            <b-form-group v-if="attachments && attachments.length > 0" class="mt-2 d-flex flex-wrap">
              <file-preview
                v-for="(attachItem, index) in attachments"
                :key="index"
                :src="attachItem.file"
                :cancelable="true"
                mime-type="image"
                class="file-upload mr-1"
                :index="index"
                @cancel="handleRemove"
              />
            </b-form-group>
            <b-form-group class="mt-2">
              <file-upload
                v-model="attach"
                class=" mw-100"
                cancelable
                type="image"
                :multiple="true"
                :placeholder="attachments && attachments.length > 0 ? $t('tasks.attach-more') : $t('tasks.attach')"
                :drop-placeholder="$t('organizations.edit.logo.drop-placeholder')"
              />
            </b-form-group>
          </div>
        </b-form>
      </b-modal>
      <!-- Edit priority -->
      <b-modal
        id="modal-edit-priority"
        :title="$t('tasks.status.priority-title')"
        :ok-title="$t('form.actions.save')"
        no-close-on-backdrop
        ok-only
        @ok="handleEdit"
      >
        <b-form-group class="flex-grow-1">
          <label for="suggestionType" class="font-weight-bold">
            {{ $t('tasks.status.priority-status') }}:</label>
          <v-select
            v-model="priority"
            name="priority"
            :placeholder="$t('form.select-placeholder')"
            :searchable="false"
            :clearable="false"
            :options="priorityOptions"
          />
        </b-form-group>
      </b-modal>
      <unshare-modal
        v-model="isUnshareModalVisible"
        model-type="task"
        @remove-item="handleRemoveItem"
      />
    </div>
    <classifiers-modal
      v-model="isClassifiersModalVisible"
      :item-key="task.key"
      morph-type="tasks"
      model-type="task"
      :classifiers="classifiers"
      @profileUpdated="fetchClassifiers"
    />
    <!-- sortedCustomFields -->
    <customfields-modal
      v-model="isCustomFieldsModalVisible"
      :item="task"
      morph-type="task"
      @profile-updated="handleProfileUpdated"
    />
  </div>
</template>

<script>
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { quillEditor } from 'vue-quill-editor';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import Placeholder from '@/assets/images/placeholders/light/general-placeholder.svg';
import UserAvatar from '@core/components/user/UserAvatar.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { AppIDLUT } from '@copernicsw/community-common';
import { checkPermissions } from '@/@core/utils/roles-utils';
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import { getImageResource } from '@/@core/utils/image-utils';
import vSelect from 'vue-select';
import MembersSelect from '@/views/apps/member/components/MembersSelect.vue';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import FileUpload from '@core/components/files/FileUpload.vue';
import FilePreview from '@core/components/files/FilePreview.vue';
import ClassifiersModal from '@/@core/components/modal/ClassifiersModal.vue';
import CustomfieldsModal from "@/@core/components/modal/CustomfieldsModal.vue";
import { dateValidator } from "@/@core/utils/validations/validators";

export default {
  name: 'TaskDetail',
  components: {
    quillEditor,
    UserAvatar,
    UnshareModal,
    vSelect,
    MembersSelect,
    SafeImg,
    FileUpload,
    FilePreview,
    ClassifiersModal,
    CustomfieldsModal,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      status: '',
      answer: {},
      isLoading: false,
      isUnshareModalVisible: false,
      projectInput: {},
      priority: '',
      assignee: '',
      dueDate: '',
      type: '',
      isSmall: false,
      action: null,
      attach: null,
      attachments: null,
      isAttaching: false,
      isClassifiersModalVisible: false,
      isCustomFieldsModalVisible: false,
    };
  },
  computed: {
    priorityOptions() {
      return [
        { label: this.$t('tasks.priority.urgent'), code: 1 },
        { label: this.$t('tasks.priority.high'), code: 2 },
        { label: this.$t('tasks.priority.normal'), code: 3 },
        { label: this.$t('tasks.priority.low'), code: 4 },
      ];
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canDelete() {
      return (
        this.isStaff
        || checkPermissions(
          'delete',
          'task',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    canEdit() {
      return (
        this.isStaff
        || checkPermissions(
          'edit',
          'task',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    tasks() {
      return this.$store.getters.tasks;
    },
    typeOptions() {
      return [
        { name: this.$t('backoffice.feedback.status.to-do'), code: 42615 },
        { name: this.$t('backoffice.feedback.status.in-progress'), code: 42616 },
        // { name: this.$t("backoffice.feedback.status.in-review"), code: XXXXXXXX },
        { name: this.$t('backoffice.feedback.status.done'), code: 42617 },
        { name: this.$t('backoffice.feedback.status.rejected'), code: 42618 },
      ];
    },
    placeholder() {
      return Placeholder;
    },
    /* item() {
        return this.$tc('backoffice.feedback.response', 0);
      }, */
    item() {
      return this.$store.getters.item('tasks', this.$route.params.id);
    },
    task() {
      return this.item;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    answers() {
      const allAnswers = this.task.answers;
      return allAnswers.reverse();
    },
    appId() {
      return 2;
    },
    app() {
      return this.$store.getters.apps.apps[this.appId];
    },
    appTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.app?.customizationName,
      );
    },
    classifiers() {
      return this.task?.classifiers;
    },
    sortedClassifiers() {
      const newObject = {};
      this.classifiers?.forEach((item) => {
        if (
          !newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ]
        ) {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ] = [item];
        } else {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ].push(item);
        }
      });
      return newObject;
    },
  },
  watch: {
    task(value) {
      this.projectInput = { ...value };
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchData();
    this.status = this.getStatus(this.task?.status);
    this.projectInput = { ...this.task };
    this.dueDate = this.task.dueDate ? new Date(this.task.dueDate)?.toISOString() : null;
    this.priority = this.task.priority ? this.priorityOptions.filter((item) => item.code == this.task.priority) : this.priorityOptions[4];
    this.assignee = this.task.assignee;
    this.isLoading = false;
    this.attachments = this.projectInput.attachments.map((item) => item);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    async handleProfileUpdated() {
      await this.fetchData();
    },
    classifierStyle(classifier) {
      return {
        color: classifier.fontColor,
        'background-color': classifier.backgroundColor,
      };
    },
    async fetchMembers(page, searchString = '') {
      return this.$store.dispatch('getItems', {
        itemType: 'communityMembers',
        searchString,
        forceAPICall: true,
        page,
        perPage: 16,
        ...(this.$store.getters.currentCollective?.parentCollective?.slug && this.$store.getters.currentCollective?.isSection ? { communitySlug: this.$store.getters.currentCollective?.parentCollective?.slug } : ''),
      });
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    async handleRemoveItem(response) {
      if (response === true) {
        try {
          await this.$store.dispatch('postItem', {
            type: 'unshareModel',
            requestConfig: {
              modelKey: this.$route.params.id,
              tableName: 'work_items',
              modelName: 'work_item',
            },
          });

          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.success', {
                item: this.$t('unshare.singular-items.role'),
              }),
              icon: 'CheckIcon',
              variant: 'success',
            },
          });
          this.$router.push({
            name: 'tasks',
            params: { communityId: this.$route.params.communityId },
          });
        } catch {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.error', {
                item: this.$t('unshare.singular-items.role'),
              }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
      }
    },
    deleteItem() {
      this.isUnshareModalVisible = true;
    },
    resolveTagVariant(tag) {
      if (tag === 1) {
        return 'danger';
      }
      if (tag === 2) {
        return 'warning';
      }
      if (tag === 3) {
        return 'info';
      }
      if (tag === 4) {
        return 'muted';
      }
      return 'light';
    },
    formatDate(date) {
      const d = new Date(date);
      return d.toLocaleString('en-GB');
    },
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'tasks',
        forceAPICall: true,
        page: 1,
        requestConfig: {
          taskKey: this.$route.params.id,
        },
      });
      this.isLoading = false;
      this.updateBreadcrumbs();
    },
    getTaskLocation(task) {
      return {
        name: 'feedback-details',
        params: { id: task.key, communityId: this.$route.params.communityId },
      };
    },
    getName(field) {
      let name = "";
      if (Array.isArray(this.task.custom)) {
        this.task.custom.map((item) => {
          if (field.key === item.key) {
            name = item.customFieldName;
          }
        });
        return translateTranslationTable(
          this.$store.state.locale.currentLocale,
          name
        );
      }
      return name;
    },
    getValue(value) {
      if (dateValidator(value)) {
        return value.split("-").reverse().join("-");
      }
      if (value === true) {
        return this.$t("yes");
      }
      if (value === false) {
        return this.$t("no");
      }
      return value;
    },
    getTypeText(tag) {
      return tag === 'bug'
        ? this.$t('backoffice.feedback.type-options.bug')
        : tag === 'feature_improvement'
          ? this.$t('backoffice.feedback.type-options.improvement')
          : this.$t('backoffice.feedback.type-options.query');
    },
    async handleCreateAnswer() {
      const statusResponse = this.getResponseStatus();
      this.task.status = statusResponse;
      try {
        await this.$store.dispatch('addTaskAnswer', {
          status: statusResponse,
          answer: this.answer,
          suggestionKey: this.$route.params.id,
        });
        this.notifySuccess(this.$t('backoffice.feedback.messages.success'));
        this.answer = {};
        this.fetchData(true);
      } catch (error) {
        this.notifyError(this.$t('backoffice.feedback.messages.error'));
      }
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: this.appTitle || this.$t('tasks.title'),
          to: { name: 'tasks' },
        },
        {
          text: this.task?.title,
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    getAnswers() {
      this.task.answer = [];
      this.task.map((item) => (item.answer !== '' && item.answer !== null
        ? this.task.answer.push(item.answer)
        : null));
      return this.task.answer.reverse();
    },
    getStatus(status) {
      const statusTranslated = {
        'To do': this.$t('backoffice.feedback.status.to-do'),
        null: this.$t('backoffice.feedback.status.to-do'),
        'In progress': this.$t('backoffice.feedback.status.in-progress'),
        'In review': this.$t('backoffice.feedback.status.in-review'),
        'Rejected': this.$t('backoffice.feedback.status.rejected'),
        Done: this.$t('backoffice.feedback.status.done'),
      };
      return statusTranslated[status];
    },
    getResponseStatus() {
      if (this.status === this.$t('backoffice.feedback.status.to-do')) {
        return 'To do';
      }
      if (this.status === this.$t('backoffice.feedback.status.in-progress')) {
        return 'In progress';
      }
      if (this.status === this.$t("backoffice.feedback.status.in-review")) {
        return "In review";
      }
      if (this.status === this.$t("backoffice.feedback.status.rejected")) {
        return "Rejected";
      }
      return 'Done';
    },
    resolveStatusVariant(status) {
      if (status === 'To do' || status === null) {
        return 'info';
      }
      if (status === 'In review') {
        return 'muted';
      }
      if (status === 'In progress') {
        return 'warning';
      }
      if (status === 'Rejected') {
        return 'danger';
      }
      return 'success';
    },
    date(date) {
      return this.$moment(date).locale(this.locale).fromNow();
    },
    async handleEdit() {
      const deleteAttachments = [];
      this.task.attachments.map(({ key }) => {
        if (!this.attachments.find((attach) => attach.key === key)) {
          deleteAttachments.push(key);
        }
      });
      this.isLoading = true;
      try {
        await this.$store.dispatch('editItem', {
          item: {
            itemType: 'tasks',
            requestConfig: {
              taskKey: this.task.key,
              title: this.projectInput.title,
              description: this.projectInput?.description,
              statusID: this.status?.code,
              priorityID: this.priority?.code,
              dueDate: this.dueDate,
              assignee: this.assignee?.key,
              ...(deleteAttachments && deleteAttachments.length > 0 ? { deleteAttachments } : ''),
            },
          },
          file: this.attach,
        });
        await this.fetchData();
        this.updateBreadcrumbs();
        setTimeout(async () => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('tasks.messages.success'),
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          });
          this.isLoading = false;
        }, 2500);
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('tasks.messages.error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    getTranslatedPriority(item) {
      const priorityLabel = this.priorityOptions.filter((priority) => priority.code === item)[0]?.label;
      if(!priorityLabel){
        return this.$t('tasks.priority.remove');
      }
      return priorityLabel;
    },
    download(source) {
      const fileName = source.split('/').pop();
      const el = document.createElement('a');
      el.setAttribute('href', source);
      el.setAttribute('download', fileName);
      document.body.appendChild(el);
      el.click();
      el.remove();
    },
    handleResize() {
      this.isSmall = window.innerWidth < 992;
    },
    handleRemove(index) {
      this.attachments.splice(index, 1);
    },
    reset() {
      this.attachments = this.task.attachments.map((item) => item);
      this.attach = null;
    },
    async fetchClassifiers() {
      await this.fetchData();
    },
    translateTranslationTable,
    attachImages() {
      this.isAttaching = true;
      this.$bvModal.show('modal-edit-tasks');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.delete-icon {
  margin-top: .80rem;
  position: relative;
  top: -48px;
  right: 0px;
}
.edit-icon {
  position: relative;
  top: -48px;
  right: 0px;
  cursor: pointer;
}
.uppercase {
  text-transform: uppercase;
}
.dot {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color:darkgrey;
}
.file-upload {
  max-height: 200px;
  max-width: 200px;
  &--upload::v-deep {
    width: auto;
    .custom-file-label {
      cursor: pointer;
      text-align: center;
      height: auto !important;
      @include button-outline-variant($primary);
      &::after {
        display: none;
      }
    }
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
